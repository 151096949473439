import React, {useState, useEffect} from 'react';
import './App.css'; // Make sure this file includes the given styles
import {v4 as uuidv4} from "uuid";
import {DemoStartPayload, StoryBlock} from './types';
import {ProducerApi} from './utils/producer_api';
import yaml from 'js-yaml';

const GREEN = "#4CAF50";
const PURPLE = "#A500FF";
const YELLOW = "#FFA500";
const RED = "#FF0000"

const loadDefaultScenePrompt = async (): Promise<string> => {
    const response = await fetch('/default_scene_prompt.txt');
    const text = await response.text();
    return text;
}

const loadDefaultStoryBlocks = async (): Promise<StoryBlock[]> => {
  try {
    const response = await fetch('/default_story_blocks.yaml');
    const yamlText = await response.text();
    const yamlData = yaml.load(yamlText) as {
      story_blocks: Array<{
        id: string;
        parents: string[];
        content: string;
      }>;
    };
    if (!yamlData) {
        console.error("Error loading default story blocks: YAML data is empty");
        return [];
    }

    // First create blocks with temporary level
    const blocks = yamlData.story_blocks.map(block => ({
      id: `${block.id}`,
      parent_ids: block.parents ? block.parents.map(parent => parent.trim()) : [],
      premise: block.content,
      level: 1, // Temporary level
      narrative: "",
      status: "NOT_PROCESSED"
    }));

    // Calculate correct levels
    blocks.forEach(block => {
      block.level = calculateBlockLevel(block.id, blocks);
    });

    return blocks;
  } catch (error) {
    console.error('Error loading default story blocks:', error);
    return [];
  }
};

// Add this helper function to calculate a block's level
function calculateBlockLevel(blockId: string, blocks: StoryBlock[]): number {
  const block = blocks.find(b => b.id === blockId);
  if (!block || !block.parent_ids || block.parent_ids.length === 0) {
    return 1; // Root level
  }

  // Recursively calculate each parent's level and add 1, take the minimum
  const allLevels = block.parent_ids.map(parentId => calculateBlockLevel(parentId, blocks));
  return Math.min(...allLevels) + 1;
}

function generateId(counter) {
    return `block_${counter}`;
}

const simplifyStoryBlockIds = (storyBlocks: StoryBlock[]) => {
    const idMapping = {};
    const mappedStoryBlocks = [];
    let counter = 1;
    storyBlocks.forEach((storyBlock) => {
        const mappedParentIds = storyBlock.parent_ids ? storyBlock.parent_ids.map(parentId => idMapping[parentId]) : null;
        const mappedBlock = {
            ...storyBlock,
            uuid: storyBlock.id,
            id: generateId(counter),
            parent_ids: mappedParentIds
        }
        mappedStoryBlocks.push(mappedBlock);
        idMapping[storyBlock.id] = mappedBlock.id;
        counter += 1;
    })
    return mappedStoryBlocks;
}

// Add new type for supported models
type SupportedModel = "anthropic" | "openai" | "deepseek";

export default function StoryBlockCreator() {
    const queryParamStoryId = new URLSearchParams(window.location.search).get('story_id');
    const [useBaftaCharacters, setUseBaftaCharacters] = useState(true);
    const [storyBlocks, setStoryBlocks] = useState<StoryBlock[]>([]);
    const [storyId, setStoryId] = useState(queryParamStoryId ? parseInt(queryParamStoryId) : null);
    const [idMapping, setIdMapping] = useState({});
    const [error, setError] = useState<string | null>(null);

    // State for creating new blocks
    const [newPremise, setNewPremise] = useState("");
    const [newParentIds, setNewParentIds] = useState<string[]>([]);
    const [newBlockId, setNewBlockId] = useState<string | null>(null);

    // For editing existing blocks
    const [editingBlockId, setEditingBlockId] = useState<string | null>(null);
    const [editedPremise, setEditedPremise] = useState("");
    const [editedParentIds, setEditedParentIds] = useState<string[]>([]);

    const [narrativeEngineStarted, setNarrativeEngineStarted] = useState(false);
    const [isStarting, setIsStarting] = useState(false);

    // For JSON import
    const [jsonInput, setJsonInput] = useState("");
    const [importError, setImportError] = useState<string | null>(null);
    const [idCounter, setIdCounter] = useState(1);

    const [newPrompt, setNewPrompt] = useState("");

    // Check if there is already a root block
    const rootBlockExists = storyBlocks.some(b => (b.parent_ids && b.parent_ids.length === 0) || (b.parent_ids === null && b.level === 1));

    // Add new state for "New Story" checkbox
    const [isNewStory, setIsNewStory] = useState(true);

    // Add new state for demo start status
    const [isDemoStarted, setIsDemoStarted] = useState(false);

    // Add new state for model selection
    const [selectedModel, setSelectedModel] = useState<SupportedModel>("anthropic");

    const producerApi = new ProducerApi();

    const copyToClipboard = () => {
        void navigator.clipboard.writeText(JSON.stringify(finalJSON, null, 2));
        alert('JSON copied to clipboard!');
    };

    function handleAddBlock(e) {
        e.preventDefault();
        if (!newPremise.trim()) return;

        // If trying to create a root block but one already exists, stop
        if (newParentIds.length === 0 && rootBlockExists) {
            alert("A root block already exists. You cannot create another root block.");
            return;
        }

        let level = 1;
        if (newParentIds.length > 0) {
            const parentBlocks = newParentIds.map(parentId => storyBlocks.find(b => b.id === parentId));
            if (parentBlocks.length > 0) {
                level = Math.min(...parentBlocks.map(b => b ? b.level : 0)) + 1;
            }
        }

        let _newBlockId = newBlockId;
        if (!_newBlockId) {
            _newBlockId = generateId(idCounter);
        }
        const newBlock: StoryBlock = {
            premise: newPremise,
            id: _newBlockId,
            parent_ids: newParentIds,
            level: level,
            narrative: "",
            status: "NOT_PROCESSED"
        };

        const updatedBlocks = [...storyBlocks, newBlock];

        // Update state
        setStoryBlocks(updatedBlocks);
        setIdCounter(idCounter + 1);
        setNewPremise("");

        // Reset newParentId only if it is invalid in the updated list
        if (!updatedBlocks.some(block => newParentIds.includes(block.id))) {
            setNewParentIds([]);
        }
    }

    function handleEditBlock(blockId: string) {
        // Toggle editing mode. If turning on, set editedPremise and editedParentId to current block's values.
        if (editingBlockId === blockId) {
            // Cancel editing if clicking again
            setEditingBlockId(null);
            setEditedPremise("");
            setEditedParentIds([]);
        } else {
            const block = storyBlocks.find(b => b.id === blockId);
            if (block) {
                setEditingBlockId(blockId);
                setEditedPremise(block.premise);
                setEditedParentIds(block.parent_ids || []);
            }
        }
    }

    function handleSaveEdit(blockId: string) {
        // Check for unique root constraint if editedParentId is ""
        if (editedParentIds.length === 0 && rootBlockExists && storyBlocks.find(b => b.id === blockId)?.parent_ids.length === 0) {
            alert("A root block already exists. You cannot make this block a root block.");
            return;
        }

        // Compute new level
        let level = 1;
        if (editedParentIds.length > 0) {
            const parentBlocks = editedParentIds.map(parentId => storyBlocks.find(b => b.id === parentId));
            if (parentBlocks.length > 0) {
                level = Math.min(...parentBlocks.map(b => b ? b.level : 0)) + 1;
            }
        }

        const updatedBlocks = storyBlocks.map(b => {
            if (b.id === blockId) {
                return {
                    ...b,
                    premise: editedPremise,
                    parent_ids: editedParentIds || [],
                    level: level,
                    status: b.status || "NOT_PROCESSED"
                };
            }
            return b;
        });

        // After changing a parent, we must ensure children levels are still correct
        function updateChildLevels(parentId, parentLevel) {
            updatedBlocks.forEach(child => {
                if (child.parent_ids.includes(parentId)) {
                    child.level = parentLevel + 1;
                    updateChildLevels(child.id, child.level);
                }
            });
        }

        updateChildLevels(blockId, level);

        setStoryBlocks(updatedBlocks);
        setEditingBlockId(null);
        setEditedPremise("");
        setEditedParentIds([]);
    }

    function deleteBlockAndChildren(blockId: string) {
        // Get all descendants of this block
        const descendants = getAllDescendants(blockId, storyBlocks);
        const idsToDelete = [blockId, ...descendants];
        const filteredBlocks = storyBlocks.filter(b => !idsToDelete.includes(b.id));
        setStoryBlocks(filteredBlocks);
    }

    function getAllDescendants(blockId: string, blocks: StoryBlock[]) {
        let children = blocks.filter(b => b.parent_ids.includes(blockId)).map(b => b.id);
        for (let childId of children) {
            children = children.concat(getAllDescendants(childId, blocks));
        }
        return children;
    }

    const [forceStart, setForceStart] = useState(false);

    const finalJSON: DemoStartPayload = {
        use_bafta_characters: useBaftaCharacters,
        story_blocks: storyBlocks,
        scene_prompt: newPrompt,
        force: forceStart,
        story_id: !isNewStory ? storyId : undefined,
        use_model: selectedModel  // Add model to the payload
    };

    const sendRequest = async (_) => {
        setNarrativeEngineStarted(false)
        setIsStarting(true)

        // Reset storyId if creating new story
        if (isNewStory) {
            setStoryId(null);
            window.history.pushState({}, '', '/');
        }

        const finalJsonProcessed = structuredClone(finalJSON)
        const localIdMapping = {}

        finalJsonProcessed.story_blocks.forEach((storyBlock) => {
            if (!localIdMapping[storyBlock.id]) {
                localIdMapping[storyBlock.id] = uuidv4();
            }
            storyBlock.id = localIdMapping[storyBlock.id];

            // Map and replace parent_ids
            if (storyBlock.parent_ids) {
                storyBlock.parent_ids.forEach(parentId => {
                    if (!localIdMapping[parentId]) {
                        localIdMapping[parentId] = uuidv4();
                    }
                });
                storyBlock.parent_ids = storyBlock.parent_ids.map(parentId => localIdMapping[parentId]);
            }
        })
        setIdMapping(localIdMapping)
        let responseData = null;
        try {
            responseData = await producerApi.startNarrativeEngine(finalJsonProcessed);
        } catch (error) {
            console.error("Error starting narrative engine:", error.message);
            setError(`Error starting narrative engine: ${error.message}`)
            setIsStarting(false)
            return
        }
        setIsStarting(false)
        if (responseData) {
            setNarrativeEngineStarted(true)
            setStoryId(parseInt(responseData.story_id));
            window.history.pushState({}, '', `/?story_id=${responseData.story_id}`);
        } else {
            setError("Error starting narrative engine")
            return
        }
        setError(null)
    }

    const getStoryBlocks = async () => {
        if (!storyId) return;
        const returnedBlocks = await producerApi.getStoryBlocks(storyId);
        const simplifiedStoryBlocks = simplifyStoryBlockIds(returnedBlocks);
        setStoryBlocks(simplifiedStoryBlocks);
    }

    useEffect(() => {
        let intervalId;
        if (storyId) {
            // Initial fetch
            getStoryBlocks();

            // Set up polling every 5 seconds
            intervalId = setInterval(() => {
                getStoryBlocks();
            }, 5000);
        }

        // Cleanup function
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [narrativeEngineStarted, storyId]);

    function validateStoryBlocks(blocks: any): blocks is StoryBlock[] {
        if (!Array.isArray(blocks)) return false;

        for (const block of blocks) {
            if (typeof block.premise !== 'string') return false;
            if (typeof block.id !== 'string') return false;
            if (block.uuid && typeof block.uuid !== 'string') return false;
            if (block.parent_ids.length !== 0 && typeof block.parent_ids !== 'string') return false;
            if (typeof block.level !== 'number') return false;
            if (typeof block.narrative !== 'string') return false;
            if (typeof block.status !== 'string') return false;
        }

        return true;
    }

    async function handleFileUpload(e: React.ChangeEvent<HTMLInputElement>) {
        if (!e.target.files || e.target.files.length === 0) return;
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (ev) => {
            try {
                const text = ev.target?.result;
                if (typeof text === "string") {
                    const parsed = JSON.parse(text);
                    if (validateStoryBlocks(parsed.story_blocks)) {
                        setStoryBlocks(parsed.story_blocks);
                        setImportError(null);
                    } else {
                        setImportError("The provided JSON does not match the required StoryBlock format.");
                    }
                }
            } catch (err) {
                console.error(err);
                setImportError("Could not parse the JSON file.");
            }
        };
        reader.readAsText(file);
    }

    function handleJsonPaste() {
        try {
            const parsed = JSON.parse(jsonInput);
            if (validateStoryBlocks(parsed.story_blocks)) {
                setStoryBlocks(parsed.story_blocks);
                setImportError(null);
                setJsonInput("");
            } else {
                setImportError("The provided JSON does not match the required StoryBlock format.");
            }
        } catch (err) {
            console.error(err);
            setImportError("Could not parse the pasted JSON.");
        }
    }

    // Add useEffect to load default blocks
    useEffect(() => {
        loadDefaultScenePrompt().then(prompt => {
            setNewPrompt(prompt);
        });
        loadDefaultStoryBlocks().then(blocks => {
            setStoryBlocks(blocks);
            setIdCounter(blocks.length + 1);
        });
    }, []);

    const [playError, setPlayError] = useState<string | null>(null);

    const handlePlayStory = async () => {
        if (!storyId) {
            setPlayError("No story ID available to play");
            return;
        }
        
        setPlayError(null);
        
        try {
            await producerApi.playStory(storyId);
        } catch (error) {
            console.error("Error playing story:", error);
            setPlayError("Error playing story");
        }
    };

    const [isPromptExpanded, setIsPromptExpanded] = useState(false);

    // Add useEffect for polling demo start status
    useEffect(() => {
        const checkDemoStatus = async () => {
            const status = await producerApi.demoStartStatus();
            setIsDemoStarted(status);
        };

        // Initial check
        checkDemoStatus();

        // Set up polling every 5 seconds
        const intervalId = setInterval(checkDemoStatus, 5000);

        // Cleanup
        return () => clearInterval(intervalId);
    }, []);

    // Add cancel handler
    const handleCancelDemo = async () => {
        try {
            await producerApi.cancelDemoStart();
            setIsDemoStarted(true); // Reset status after cancellation
        } catch (error) {
            console.error("Error canceling demo:", error);
            setError("Error canceling demo");
        }
    };

    return (
        <div className="App">
            <div className="two-pane-layout">
                <div className='static-dashboard'>
                    <div className="title-container">
                        <h1 className="title">Story Block Creator</h1>
                    </div>

                    <div style={{marginBottom: "20px"}}>
                        <select
                            value={selectedModel}
                            onChange={(e) => setSelectedModel(e.target.value as SupportedModel)}
                            style={{
                                marginRight: "10px",
                                padding: "5px",
                                borderRadius: "4px"
                            }}
                        >
                            <option value="anthropic">Anthropic</option>
                            <option value="openai">OpenAI</option>
                            <option value="deepseek">DeepSeek</option>
                        </select>

                        <label style={{color: "white", fontSize: "0.5em", marginRight: "10px"}}>
                            <input
                                type="checkbox"
                                checked={useBaftaCharacters}
                                onChange={(e) => setUseBaftaCharacters(e.target.checked)}
                                disabled={true}
                            />
                            Use BAFTA Characters
                        </label>
                        <label style={{color: "white", fontSize: "0.5em", marginRight: "10px"}}>
                            <input
                                type="checkbox"
                                checked={forceStart}
                                onChange={(e) => setForceStart(e.target.checked)}
                            />
                            Force Start
                        </label>
                        <label style={{color: "white", fontSize: "0.5em"}}>
                            <input
                                type="checkbox"
                                checked={isNewStory}
                                onChange={(e) => setIsNewStory(e.target.checked)}
                            />
                            New Story
                        </label>
                    </div>

                    <details style={{ marginBottom: "20px" }} open={isPromptExpanded}>
                        <summary 
                            style={{ color: "white", cursor: "pointer" }}
                            onClick={() => setIsPromptExpanded(!isPromptExpanded)}
                        >
                            Scene Prompt
                        </summary>
                        <textarea
                            className="text-input"
                            style={{
                                width: "100%",
                                height: "150px",
                                marginTop: "10px"
                            }}
                            value={newPrompt}
                            onChange={(e) => setNewPrompt(e.target.value)}
                            placeholder="Enter scene prompt..."
                        />
                    </details>

                    <h2 style={{color: "white", marginTop: "40px"}}>Add a New Story Block</h2>
                    <form onSubmit={handleAddBlock} style={{marginBottom: "20px"}}>
                        <div className="input-group" style={{marginBottom: "10px"}}>
                            <textarea
                                className="text-input"
                                style={{width: "400px", height: "150px"}}
                                placeholder="Enter premise"
                                value={newPremise}
                                onChange={(e) => setNewPremise(e.target.value)}
                            />
                        </div>
                        <div className="input-group" style={{marginBottom: "10px"}}>
                            <input
                                type="text"
                                className="text-input"
                                style={{width: "320px"}}
                                placeholder="Enter block ID (optional)"
                                value={newBlockId}
                                onChange={(e) => setNewBlockId(e.target.value)}
                            />
                        </div>
                        <h3 style={{color: "white", marginTop: "40px"}}>Parent</h3>
                        <div className="input-group" style={{marginBottom: "10px"}}>
                            <select
                                className="text-input"
                                style={{width: "320px"}}
                                value={newParentIds}
                                onChange={(e) => setNewParentIds(Array.from(e.target.selectedOptions).map(option => option.value))}
                                multiple
                            >
                                <option value="">-- No Parent (new root) --</option>
                                {storyBlocks.map(block => (
                                    <option key={block.id} value={block.id}>
                                        {block.id} - {block.premise.substring(0, 30) + (block.premise.length > 30 ? "..." : "")}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <button className="create-button" type="submit">Add Story Block</button>
                    </form>

                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <button className="start-narrative-engine" type="submit" onClick={sendRequest}>
                            Start Narrative Engine 🚀
                        </button>

                        <button 
                            className="start-narrative-engine" 
                            onClick={handleCancelDemo}
                            style={{ 
                                backgroundColor: '#f44336',
                                cursor: isDemoStarted ? 'pointer' : 'not-allowed' 
                            }}
                            disabled={!isDemoStarted}
                        >
                            Cancel Current Story ⚠️
                        </button>

                        {storyId && (
                            <button 
                                className="start-narrative-engine" 
                                onClick={handlePlayStory}
                            >
                                Replay Story 🎭
                            </button>
                        )}
                    </div>

                    {playError && <h3 style={{color: "red", marginTop: "40px"}}>{playError}</h3>}

                    {isStarting && <h3 style={{color: "white", marginTop: "40px"}}>Starting Narrative Engine...</h3>}
                    {narrativeEngineStarted &&
                        <h3 style={{color: "white", marginTop: "40px"}}>Started Narrative Engine</h3>}
                    {error && <h3 style={{color: "red", marginTop: "40px"}}>{error}</h3>}

                    <h2 style={{color: "white", marginTop: "40px"}}>Import Story Blocks</h2>
                    <p style={{color: "white"}}>Upload a JSON file or paste raw JSON:</p>
                    <input
                        type="file"
                        accept=".json"
                        onChange={handleFileUpload}
                        style={{marginBottom: "10px", color: "white"}}
                    />
                    <textarea
                        className="text-input"
                        style={{width: "100%", height: "100px", marginBottom: "10px"}}
                        placeholder="Paste JSON here"
                        value={jsonInput}
                        onChange={(e) => setJsonInput(e.target.value)}
                    />
                    <button className="create-button" onClick={handleJsonPaste}>Import from Pasted JSON</button>
                    {importError && <p style={{color: "red"}}>{importError}</p>}
                </div>

                <div className='scrollable-pane'>
                    <h2 style={{color: "white"}}>Existing Story Blocks</h2>
                    {storyBlocks.length === 0 ? (
                        <p style={{color: "white"}}>No blocks yet. Create the root block first!</p>
                    ) : (
                        <ul style={{
                            listStyle: "none",
                            padding: 0,
                            color: "white",
                            maxWidth: "600px",
                            margin: "0 auto"
                        }}>
                            {storyBlocks.map(block => (
                                <li key={block.id} style={{
                                    border: "1px solid #61dafb",
                                    borderRadius: "4px",
                                    padding: "10px",
                                    marginBottom: "10px"
                                }}>
                                    <div>
                                        <strong>ID:</strong> {block.id}<br/>
                                        <strong>UUID:</strong> {block.uuid || "Not Available"}<br/>
                                        <strong>Parents:</strong> {block.parent_ids ? block.parent_ids.join(',') : "None"}<br/>
                                        <strong>Level:</strong> {block.level}<br/>
                                        <strong>Status:</strong> <span style={{
                                        color: block.status === "RENDERED" ? GREEN :
                                            block.status === "PROCESSED" ? PURPLE :
                                                block.status === "IN_PROGRESS" ? YELLOW : RED
                                    }}>{block.status}</span>
                                    </div>
                                    {editingBlockId === block.id ? (
                                        <div style={{marginTop: "10px"}}>
                                            <textarea
                                                className="text-input"
                                                style={{width: "100%", minHeight: "60px"}}
                                                value={editedPremise}
                                                onChange={(e) => setEditedPremise(e.target.value)}
                                            />
                                            <div style={{marginTop: "10px"}}>
                                                <label style={{color: "white"}}>
                                                    Parents:
                                                    <select
                                                        className="text-input"
                                                        style={{marginLeft: "10px"}}
                                                        value={editedParentIds} 
                                                        onChange={(e) => {
                                                            const selectedOptions = Array.from(e.target.selectedOptions).map(option => option.value);
                                                            setEditedParentIds(selectedOptions);
                                                        }}
                                                        multiple
                                                    >
                                                        {(!rootBlockExists || storyBlocks.find(b => b.id === block.id)?.parent_ids.length === 0) &&
                                                            <option value="">No Parent (Root)</option>
                                                        }
                                                        {storyBlocks.filter(b => b.id !== block.id).map(b => (
                                                            <option key={b.id} value={b.id}>
                                                                {b.id} - {b.premise.substring(0, 30) + (b.premise.length > 30 ? "..." : "")}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </label>
                                            </div>
                                            <div style={{marginTop: "10px"}}>
                                                <button className="create-button"
                                                        onClick={() => handleSaveEdit(block.id)}>Save
                                                </button>
                                                <button className="create-button" style={{
                                                    marginLeft: "10px",
                                                    backgroundColor: "#f44336",
                                                    color: "white"
                                                }} onClick={() => handleEditBlock(block.id)}>Cancel
                                                </button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div style={{marginTop: "10px"}}>
                                            <em>{block.premise}</em>
                                            <div style={{marginTop: "10px"}}>
                                                <button className="create-button"
                                                        onClick={() => handleEditBlock(block.id)}>Edit
                                                </button>
                                                <button className="create-button" style={{
                                                    marginLeft: "10px",
                                                    backgroundColor: "#f44336",
                                                    color: "white"
                                                }} onClick={() => deleteBlockAndChildren(block.id)}>Delete
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                    <details>
                                        <summary>Full Script</summary>
                                        <pre style={{whiteSpace: 'pre-wrap', wordWrap: 'break-word'}}>
                                            {block.narrative ? block.narrative : "Script not yet generated"}
                                        </pre>
                                    </details>
                                </li>
                            ))}
                        </ul>
                    )}

                    <h2 style={{color: "white"}}>JSON Output (NOTE: UUIDs will be added)</h2>
                    <pre style={{
                        background: "#333",
                        color: "#fff",
                        padding: "10px",
                        textAlign: "left",
                        fontSize: "14px",
                        maxWidth: "600px",
                        margin: "0 auto"
                    }}>{JSON.stringify(finalJSON, null, 2)}
                    </pre>
                    <button
                        onClick={copyToClipboard}
                        style={{
                            display: "block",
                            margin: "10px auto",
                            padding: "10px 20px",
                            fontSize: "14px",
                            backgroundColor: "#007BFF",
                            color: "#fff",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer"
                        }}
                    >
                        Copy JSON to Clipboard
                    </button>
                </div>
            </div>
        </div>
    );
}