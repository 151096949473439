import { DemoStartPayload, DemoStartResponse, StoryBlock } from "../types";
import { getEnvVar } from "./env";

export class ProducerApi {
  private readonly PRODUCER_URL: string;
  private readonly AUTH_TOKEN: string;

  constructor() {
    this.PRODUCER_URL = getEnvVar("REACT_APP_PRODUCER_URL");
    if (!this.PRODUCER_URL) {
      throw new Error("REACT_APP_PRODUCER_URL environment variable is not set");
    }
    this.AUTH_TOKEN = getEnvVar("REACT_APP_API_KEY");
    if (!this.AUTH_TOKEN) {
      throw new Error("REACT_APP_API_KEY environment variable is not set");
    }
  }

  public async startNarrativeEngine(finalJsonProcessed: DemoStartPayload) {
    const response = await fetch(`${this.PRODUCER_URL}/demo_start`, {
      method: "POST",
      headers: {
        Authorization: this.AUTH_TOKEN,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(finalJsonProcessed),
    });
    if (!response.ok) {
      let errorData: { detail?: string } = {};
      try {
        errorData = await response.json();
      } catch (e) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      throw new Error(
        `HTTP error! status: ${response.status}, message: ${
          errorData.detail || "No error message provided"
        }`
      );
    }

    const responseData = await response.json();
    return responseData as DemoStartResponse;
  }

  public async cancelDemoStart() {
    const response = await fetch(`${this.PRODUCER_URL}/cancel_demo_start`, {
      method: "POST",
      headers: {
        Authorization: this.AUTH_TOKEN,
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  }

  public async demoStartStatus(): Promise<boolean> {
    const response = await fetch(`${this.PRODUCER_URL}/demo_start_status`, {
      method: "GET",
      headers: {
        Authorization: this.AUTH_TOKEN,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData.status;
  }

  public async getStoryBlocks(storyId: number) {
    const response = await fetch(
      `${this.PRODUCER_URL}/story_blocks?story_id=${storyId}`,
      {
        method: "GET",
        headers: {
          Authorization: this.AUTH_TOKEN,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData.story_blocks as StoryBlock[];
  }

  public async playStory(storyId: number) {
    const response = await fetch(`${this.PRODUCER_URL}/play_story/${storyId}`, {
      method: "GET",
      headers: {
        Authorization: this.AUTH_TOKEN,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData;
  }
}
